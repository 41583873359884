import { useGoogleTagManager } from '@/composables/googleAnalytics/GoogleTagManager'
import { GtmVariablesEnum } from '@/composables/googleAnalytics/GoogleTagManager.enum'

export const useGoogleAnalyticsHelpers = () => {
  const { gtmPush } = useGoogleTagManager()

  const portalVisibilitySearchBarAnalytics = (
    selectedServiceTemplateId: number,
    selectedServiceTemplateNameFr: string,
    postalCode: string | null
  ) => {
    gtmPush('portalVisibilitySearchBar', {
      [GtmVariablesEnum.SERVICE_TEMPLATE_ID]: selectedServiceTemplateId,
      [GtmVariablesEnum.SERVICE_TEMPLATE_NAME_FR]: selectedServiceTemplateNameFr,
      [GtmVariablesEnum.SLICED_POSTAL_CODE]: postalCode?.slice(0, 3) || null // null if geolocated
    })
  }

  const portalVisibilitySelectCategoryAnalytics = (id: string, nameFr: string) => {
    gtmPush('portalVisibilitySelectCategory', {
      [GtmVariablesEnum.SERVICE_UNIFIED_ID]: id,
      [GtmVariablesEnum.SERVICE_UNIFIED_NAME_FR]: nameFr
    })
  }

  const portalVisibilitySelectSubCategoryAnalytics = (id: string, nameFr: string) => {
    gtmPush('portalVisibilitySelectSubCategory', {
      [GtmVariablesEnum.SERVICE_UNIFIED_ID]: id,
      [GtmVariablesEnum.SERVICE_UNIFIED_NAME_FR]: nameFr
    })
  }

  const portalVisibilitySelectServiceAnalytics = (id: number, nameFr: string) => {
    gtmPush('portalVisibilitySelectService', {
      [GtmVariablesEnum.SERVICE_TEMPLATE_ID]: id,
      [GtmVariablesEnum.SERVICE_TEMPLATE_NAME_FR]: nameFr
    })
  }

  const portalVisibilitySelectClinicAnalytics = (place, service) => {
    gtmPush('portalVisibilitySelectClinic', {
      portalSelectClinic: {
        place: place.nameFr,
        establishment: place.establishment,
        distance: place.distance,
        availsNextSevenDays: service.availabilities.t07,
        availsAfterSevenDays: service.availabilities.ta7,
        establishmentType: place.establishmentType
      },
      [GtmVariablesEnum.SERVICE_TEMPLATE_ID]: service.stId,
      [GtmVariablesEnum.SERVICE_TEMPLATE_NAME_FR]: service.nameFr,
      [GtmVariablesEnum.ESTABLISHMENT_SERVICE_TEMPLATE_ID]: service.stId,
      [GtmVariablesEnum.ESTABLISHMENT_SERVICE_TEMPLATE_PRICING_ENABLED]: service.pricingEnabled,
      [GtmVariablesEnum.ESTABLISHMENT_SERVICE_TEMPLATE_NAME_FR]: service.stDescriptionFr,
      [GtmVariablesEnum.ESTABLISHMENT_ID]: place.establishment,
      [GtmVariablesEnum.ESTABLISHMENT_TYPE]: place.establishmentType,
      [GtmVariablesEnum.PLACE_NAME]: place.nameFr,
      [GtmVariablesEnum.PLACE_POSTAL_CODE]: place.postalCode,
      [GtmVariablesEnum.DISTANCE]: place.distance,
      [GtmVariablesEnum.PLACE_REGION]: place.region,
      [GtmVariablesEnum.ESTABLISHMENT_NAME]: place.establishmentName,
      availsNextSevenDays: service.availabilities.t07,
      availsAfterSevenDays: service.availabilities.ta7,
      placeRegion: place.region,
      headOfficeRegionCode: place.headOfficeRegionCode
    })
  }

  const portalVisibilitySearchBarKeywordAnalytics = (keyword, searchNextStep) => {
    gtmPush('portalVisibilitySearchBarKeyword', {
      [GtmVariablesEnum.KEYWORD]: keyword,
      [GtmVariablesEnum.KEYWORD_NEXT_STEP]: searchNextStep
    })
  }

  const portalVisibilityFilterAnalytics = (filterType: string, filterValue: string | null) => {
    gtmPush('portalVisibilityFilter', {
      [GtmVariablesEnum.FILTER_TYPE]: filterType,
      [GtmVariablesEnum.FILTER_VALUE]: filterValue
    })
  }

  return {
    portalVisibilitySearchBarAnalytics,
    portalVisibilitySelectCategoryAnalytics,
    portalVisibilitySelectSubCategoryAnalytics,
    portalVisibilitySelectServiceAnalytics,
    portalVisibilitySelectClinicAnalytics,
    portalVisibilitySearchBarKeywordAnalytics,
    portalVisibilityFilterAnalytics
  }
}
